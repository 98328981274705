import { AdminPermissionEnum } from "@graphql/graphql";
import React from "react";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  refreshToken: string | null;
  token: string | null;
  adminRole: AdminRole | null;
  role: string | null;
  setItem: (key: string, value: string, callback?: VoidFunction) => void;
  getItem: (key: string) => string | JSON;
  removeItem: (key: string) => void;
  navigate: (url: string) => void;
}
export type AdminRole = {
  [key: string]: AdminPermissionEnum | Boolean;
  // 디자인관리
  designManagement: AdminPermissionEnum;

  // 사용자관리
  userManagement: AdminPermissionEnum;

  // 승인요청관리
  requestManagement: AdminPermissionEnum;

  // 수업관리
  lessonManagement: AdminPermissionEnum;

  // 교재관리
  bookManagement: AdminPermissionEnum;

  // 정산관리
  calculationManagement: AdminPermissionEnum;

  // 운영관리
  operationManagement: AdminPermissionEnum;

  // 통계관리
  statisticManagement: AdminPermissionEnum;

  // 출금관리
  withdrawManagement: AdminPermissionEnum;

  // 채팅상담권한
  chatCounselling: Boolean;

  // 문의권한
  faq: Boolean;
};
export const AuthContext = React.createContext<AuthContextType>(null!);
export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigation = useNavigate();

  const refreshToken: string | null = localStorage.getItem("REFRESH_TOKEN") as string;
  const token: string | null = localStorage.getItem("TOKEN") as string;
  const adminRole: AdminRole | null = JSON.parse(localStorage.getItem("ADMINROLE") as string);
  const role: string | null = localStorage.getItem("ROLE") as string;
  
  const setItem = (key: string, value: string, callback?: VoidFunction) => {
    localStorage.setItem(key, value);
    return callback && callback();
  };
  const getItem = (key: string) => {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch {
      return localStorage.getItem(key);
    }
  };
  const removeItem = (key: string) => {
    localStorage.removeItem(key);
  };

  const navigate = (url: string) => {
    navigation(url, { replace: true });
  };

  const value = { refreshToken, token, adminRole, role, setItem, getItem, removeItem, navigate };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
