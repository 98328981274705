import React, { ReactNode, useCallback, useState } from "react";

interface RegisterUSerProviderProps {
  children: ReactNode;
}

interface RegisterUserContextType {
  registerUserTypeArr: string[];
  handleRegisterUserTypeCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const RegisterUserContext = React.createContext<RegisterUserContextType>({
  registerUserTypeArr: [],
  handleRegisterUserTypeCheckBox: () => {},
});

export const RegisterUserProvider = ({ children }: RegisterUSerProviderProps) => {
  const [registerUserTypeArr, setRegisterUserTypeArr] = useState<string[]>(["학생", "학부모"]);
  const handleRegisterUserTypeCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      const type = e.target.id;
      if (type === "전체") {
        if (checked) {
          setRegisterUserTypeArr(["전체", "학생", "학부모", "선생님", "테스터"]);
        } else {
          setRegisterUserTypeArr([]);
        }
      } else {
        if (checked) {
          setRegisterUserTypeArr(prev => {
            if (!prev.includes("전체") && prev.length === 3) {
              return [...prev, type, "전체"];
            }
            return [...prev, type];
          });
        } else {
          setRegisterUserTypeArr(prev => prev.filter(item => item !== type && item !== "전체"));
        }
      }
    },
    [setRegisterUserTypeArr],
  );

  return (
    <RegisterUserContext.Provider
      value={{
        registerUserTypeArr,
        handleRegisterUserTypeCheckBox,
      }}
    >
      {children}
    </RegisterUserContext.Provider>
  );
};
